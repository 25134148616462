import {storeToRefs} from "pinia";
import {useUserStore} from "~~/stores/user";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);
    try {
        // console.log("logged in middleware");
        await userStore.getUser();

        if ((user.value.isProfileSetup) && (to.path !== "/admin")) {
            return navigateTo("/admin");
        } else if ((!user.value.isProfileSetup) && (to.path !== "/v2/setup-profile")) {
            return navigateTo("/v2/setup-profile");
        }

        return true;

    } catch (error) {
        return true;
    }
});
